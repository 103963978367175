var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilChartPie"}}),_vm._v(" Danh sách dự thưởng ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.showFilter = !_vm.showFilter;
            })($event)}}},[(_vm.showFilter)?_c('CIcon',{attrs:{"name":"cil-chevron-top"}}):_c('CIcon',{attrs:{"name":"cil-chevron-bottom"}})],1),[(_vm.exporting)?_c('CSpinner',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm"}}):_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm","title":"export"},on:{"click":function($event){$event.preventDefault();return _vm.exportXlsx($event)}}},[_c('CIcon',{attrs:{"name":"cib-experts-exchange"}}),_vm._v(" Export Excel ")],1)]],2)]),_c('CCardBody',[(_vm.showFilter)?_c('filter-box',{on:{"done":function (e) {
            _vm.filters = e;
          }}}):_vm._e(),_c('odata-table',{ref:"lstJoiningReward",attrs:{"url":"odata/JoiningReward","tableClass":"table-striped","filter":_vm.filter,"select":"Id,CustName,Mobile,LuckyCode,CreatedAt,RewardName,RewardAt","expand":"Shop($select=Name)","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          ShopName: {
            field: 'Shop/Name',
            display: 'Cửa hàng',
            sortable: true,
            style: 'min-width: 150px',
          },
          CustName: {
            field: 'CustName',
            display: 'Tên KH',
            sortable: true,
            style: 'min-width: 100px',
          },
          Mobile: {
            field: 'Mobile',
            display: 'Điện thoại',
            sortable: true,
            style: 'min-width: 120px',
          },
          LuckyCode: {
            field: 'LuckyCode',
            display: 'Mã dự thưởng',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
          RewardName: {
            field: 'RewardName',
            display: 'Giải thưởng',
            sortable: true,
            style: 'min-width: 150px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(" "+_vm._s(i + 1 + (_vm.$refs.lstJoiningReward.page - 1) * _vm.pageSize)+". ")]),_c('td',[_vm._v(_vm._s(row.Shop.Name))]),_c('td',[_vm._v(_vm._s(row.CustName))]),_c('td',[_vm._v(_vm._s(row.Mobile))]),_c('td',[_vm._v(_vm._s(row.LuckyCode))]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .utcOffset("-08:00") .format("DD/MM/YY HH:mm"))+" ")]),_c('td',[_vm._v(" "+_vm._s(row.RewardName)+" ")])])})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }