<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilChartPie" />
          Danh sách dự thưởng
        </span>
        <div class="d-inline-block float-right align-right">
          <CButton
            color="info"
            size="sm"
            class="mr-2"
            @click.prevent="
              (e) => {
                showFilter = !showFilter;
              }
            "
          >
            <CIcon v-if="showFilter" name="cil-chevron-top" />
            <CIcon v-else name="cil-chevron-bottom" />
          </CButton>

          <template>
            <CSpinner v-if="exporting" color="primary" size="sm" class="mr-2" />
            <CButton
              v-else
              color="primary"
              size="sm"
              class="mr-2"
              title="export"
              @click.prevent="exportXlsx"
            >
              <CIcon name="cib-experts-exchange" />
              Export Excel
            </CButton>
          </template>
        </div>
      </CCardHeader>
      <CCardBody>
        <filter-box
          v-if="showFilter"
          @done="
            (e) => {
              filters = e;
            }
          "
        />
        <odata-table
          ref="lstJoiningReward"
          url="odata/JoiningReward"
          tableClass="table-striped"
          :filter="filter"
          select="Id,CustName,Mobile,LuckyCode,CreatedAt,RewardName,RewardAt"
          :expand="`Shop($select=Name)`"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            ShopName: {
              field: 'Shop/Name',
              display: 'Cửa hàng',
              sortable: true,
              style: 'min-width: 150px',
            },
            CustName: {
              field: 'CustName',
              display: 'Tên KH',
              sortable: true,
              style: 'min-width: 100px',
            },
            Mobile: {
              field: 'Mobile',
              display: 'Điện thoại',
              sortable: true,
              style: 'min-width: 120px',
            },
            LuckyCode: {
              field: 'LuckyCode',
              display: 'Mã dự thưởng',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
            RewardName: {
              field: 'RewardName',
              display: 'Giải thưởng',
              sortable: true,
              style: 'min-width: 150px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>
                {{ i + 1 + ($refs.lstJoiningReward.page - 1) * pageSize }}.
              </td>
              <td>{{ row.Shop.Name }}</td>
              <td>{{ row.CustName }}</td>
              <td>{{ row.Mobile }}</td>
              <td>{{ row.LuckyCode }}</td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .utcOffset("-08:00")
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
              <td>
                {{ row.RewardName }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import XLSX from "xlsx";
import odataTable from "@/components/odataTable.vue";
import filterBox from "./Filter.vue";

export default {
  name: "RewardReport",
  components: {
    odataTable,
    filterBox,
  },
  data() {
    return {
      pageSize: 10,
      showFilter: false,
      filters: null,
      exporting: false,
    };
  },
  computed: {
    filter() {
      let filters = [`Status eq ${this.$const.STATUS.Active}`];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  methods: {
    async exportXlsx() {
      let oCpnent = this.$refs.lstJoiningReward;
      if (!oCpnent.total) {
        alert("Không có dữ liệu!");
        return;
      }

      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = [
        "Cửa hàng",
        "Tên KH",
        "Điện thoại",
        "Mã dự thưởng",
        "Ngày",
        "Giải thưởng",
        "Ngày trả thưởng",
      ];

      /* XLS Rows Data */
      var xlsRows = [];

      let pageSize = 100;
      var pages =
        oCpnent.total > pageSize ? Math.ceil(oCpnent.total / pageSize) : 1;

      this.exporting = true;
      for (let page = 1; page <= pages; page++) {
        let params = {
          $select: oCpnent.select,
          $expand: oCpnent.expand,
          $orderby: oCpnent.orderBy,
          $top: pageSize,
          $skip: page ? (page - 1) * pageSize : 0,
        };
        if (oCpnent.filter) {
          params.$filter = oCpnent.filter;
        }
        let resp = await this.$http.get(oCpnent.url, {
          params,
        });
        if (
          resp &&
          resp.status == 200 &&
          resp.data.value &&
          resp.data.value.length
        ) {
          let items = resp.data.value;
          xlsRows = [
            ...xlsRows,
            ...items.map((row) => {
              return {
                ShopName: row.Shop.Name,
                Name: row.CustName,
                Mobile: row.Mobile,
                LuckyCode: row.LuckyCode,
                CreatedAt: this.$moment
                  .utc(row.CreatedAt)
                  .local()
                  .format("DD/MM/YY HH:mm"),
                RewardName: row.RewardName,
                RewardAt: row.RewardAt
                  ? this.$moment
                      .utc(row.RewardAt)
                      .local()
                      .format("DD/MM/YY HH:mm")
                  : "",
              };
            }),
          ];
        }
      }
      this.exporting = false;

      if (!xlsRows.length) {
        alert("Không có dữ liệu!");
        return;
      }

      createXLSLFormatObj.push(xlsHeader);
      xlsRows.forEach((value) => {
        var innerRowData = [];
        Object.keys(value).forEach((p) => {
          innerRowData.push(value[p]);
        });
        createXLSLFormatObj.push(innerRowData);
      });

      /* File Name */
      var filename = `DsDuThuong_${this.$moment().format("YYYYMMDD")}.xlsx`;

      /* Sheet Name */
      var ws_name = "KhachHang";

      if (typeof console !== "undefined") console.log(new Date());
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      ws["!cols"] = this.fitToColumn(createXLSLFormatObj);

      /* Add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      /* Write workbook and Download */
      if (typeof console !== "undefined") console.log(new Date());
      XLSX.writeFile(wb, filename);
      if (typeof console !== "undefined") console.log(new Date());
    },

    fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
        ),
      }));
    },
  },
};
</script>
