<template>
  <div>
    <div class="form-row">
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">Cửa hàng</label>
            <v-select
              v-model="shopId"
              :options="shops"
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Cửa hàng"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <CInput
          label="Tên KH"
          placeholder="Tên khách hàng"
          v-model="customerName"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="4">
        <CInput
          label="Số ĐT"
          placeholder="Số điện thoại"
          v-model="customerMobile"
          @keyup.enter="filter"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
        />
      </CCol>
      <CCol md="4">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Từ ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdFrom"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Đến ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdTo"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4" class="text-right">
        <CButton color="primary" class="mr-2 mb-3" @click="filter">
          <CIcon name="cil-search" custom-classes="c-icon m-0" />
          Tìm kiếm
        </CButton>

        <CButton color="secondary" class="mb-3" @click="clear">
          <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
          Đặt lại
        </CButton>
      </CCol>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      customerName: null,
      customerMobile: null,
      createdFrom: null,
      createdTo: null,
      shopId: null,
      shops: [],
    };
  },
  async mounted() {
    this.shops = await this.loadShops();
  },
  methods: {
    async loadShops() {
      let shops = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Shop", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
        },
      });
      if (resp && resp.status == 200) {
        shops = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return shops;
    },

    filter() {
      let filters = [];
      this.customerName &&
        filters.push(`contains(Name, '${this.customerName}')`);
      this.customerMobile &&
        filters.push(`contains(Mobile, '${this.customerMobile}')`);

      if (this.createdFrom) {
        let createdFrom = this.$moment(this.createdFrom).toISOString();
        filters.push(`CreatedAt ge ${createdFrom}`);
      }
      if (this.createdTo) {
        let createdTo = this.$moment(this.createdTo)
          .add(1, "days")
          .toISOString();
        filters.push(`CreatedAt lt ${createdTo}`);
      }
      this.customerName && filters.push(`ShopId eq ${this.shopId}`);

      this.$emit("done", filters);
    },

    clear() {
      this.customerName = null;
      this.customerMobile = null;
      this.shopId = null;
      this.createdFrom = null;
      this.createdTo = null;
    },
  },
};
</script>
